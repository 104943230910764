h1 {
  font-size: 1.125rem;
  line-height: 150%;
  text-transform: uppercase;
  margin-top: 16px;
  text-align: center;

  & + * {
    margin-top: toRem(64);
  }
}

h2 {
  line-height: 150%;
}

a {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
