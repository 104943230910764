@import './mixins.scss';
@import './reset.scss';
@import './colors.scss';
@import './fonts.scss';
@import './typography.scss';

@import './components/input.scss';
@import './components/select.scss';
@import './components/button.scss';
@import './components/toggle.scss';
@import './components/form.scss';
@import './components/notifications.scss';

@import './pages/register.scss';
@import './pages/authorize.scss';
@import './pages/verify.scss';
@import './pages/consent.scss';
@import './pages/password.scss';
@import './pages/error.scss';
@import './pages/not-found.scss';
@import './pages/sso-already-used.scss';

@import './footer.scss';

:root {
  color-scheme: dark;
}

html {
  min-height: 100%;
  font-size: 15px;

  @include breakpoint-desktop(xl) {
    font-size: 16px;
  }
}

body {
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  font-family: 'Zelezna', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: var(--colorTextPrimary);

  background: linear-gradient(127deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 59.28%);
  background-color: var(--colorBgPrimary);
  background-repeat: repeat-x;
}

header {
  margin-bottom: toRem(36);
  visibility: hidden;

  @include breakpoint-desktop(min) {
    visibility: visible;
    margin-bottom: toRem(80);
  }

  nav {
    height: toRem(72);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 16px;
    gap: toRem(32);

    @include breakpoint-desktop(min) {
      height: toRem(80);
      padding: 0 toRem(60);
    }

    .nav__previous {
      position: relative;
      margin-right: auto;
      display: inline-flex;
      align-items: center;
      gap: 16px;

      &::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        background: transparent url(/src/images/svg/arrow-left.svg) no-repeat center;
      }
    }

    > a {
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
    }

    > span[role='separator'] {
      display: inline-block;
      flex: 0 0 auto;
      width: 8px;
      height: 24px;
      background: transparent url(/src/images/svg/divider.svg) no-repeat center;
    }
  }
}

main {
  margin: 0 auto;
  width: 100%;
  max-width: 388px;

  @include breakpoint-desktop(max) {
    padding: 0 20px;
  }

  .logo {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
  }
}

html {
  &[data-scope='register'] {
    header {
      @include breakpoint-desktop(max) {
        visibility: visible;
        margin-bottom: 40px;

        nav {
          > * {
            &:not(.nav__previous) {
              display: none;
              visibility: hidden;
            }
          }
        }
      }
    }
  }
}

a,
button,
.input,
select.language-select,
.select,
.toggle {
  transition: 0.3s ease-out;
}

a:focus-visible {
  outline: 2px solid var(--colorOutline);
  outline-offset: 4px;
}

select.language-select {
  &:focus-visible {
    outline: 2px solid var(--colorOutline);
    outline-offset: 4px;
  }
}

input[data-autocompleted] {
  background-color: transparent !important;
}

::-ms-reveal {
  display: none;
}

.user-card {
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
  border-radius: 88px;
  background: var(--colorBgTertiary, #292929);
  backdrop-filter: blur(64px);
  word-break: break-all;
  margin-bottom: 32px;

  > picture {
    position: relative;
    border-radius: 100%;
    overflow: hidden;
    flex: 0 0 auto;
    width: toRem(64);
    height: toRem(64);

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      color: transparent;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
    }
  }
  strong {
    font-size: toRem(16);
    text-transform: uppercase;
  }

  span {
    display: block;
    margin-top: 4px;
    color: var(--colorTextSecondary);
    font-size: toRem(12);
    font-style: normal;
    font-weight: 400;
    line-height: 128.571%;
  }

  @include breakpoint-desktop(min) {
    padding: 20px;
    gap: 20px;

    strong {
      font-size: toRem(20);
    }

    span {
      font-size: toRem(14);
    }
  }
}

::-webkit-contacts-auto-fill-button,
::-webkit-credentials-auto-fill-button {
  background-color: #fff; // Change the background color of the button
  color: #fff; // Change the color of the button text
}

input:-webkit-autofill,
input[data-com-onepassword-filled] {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff !important;
}

// Maybe later?
// input:-webkit-autofill,
// input:-webkit-autofill:focus {
// -webkit-box-shadow: 0 0 0 60px #c8aa5a inset !important;
// background-color: #c8aa5a !important;
// background-clip: content-box !important;
// transition: background-color 600000s 0s, color 600000s 0s;
// }
