.form-consent {
  display: flex;
  flex-direction: column;
  margin-top: toRem(32);

  ul {
    list-style: disc;
    padding-left: toRem(32);
    margin-top: 8px;

    li {
      font-size: toRem(20);
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  button[type='submit'] {
    margin: toRem(32) 0 toRem(24);

    &[data-loading='true'] + {
      button {
        pointer-events: none;

        border-color: transparent;
        color: var(--colorBgSemiTransparent32);
        background: var(--colorBgSemiTransparent32);
        backdrop-filter: blur(64px);
      }
    }
  }

  p {
    font-size: toRem(20);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    strong {
      font-weight: 500;
      color: var(--colorTextPrimary);
    }

    small {
      display: block;
      margin-top: toRem(24);
      font-size: toRem(16);
      line-height: 171.429%;
      color: var(--colorTextPrimary);

      a {
        font-size: toRem(16);
        line-height: 150%;
        color: var(--colorTextPrimary);
      }
    }
  }
}

.consent-page-container {
  > p {
    line-height: toRem(24);
    margin-top: toRem(24);
  }
}

.form-consent-signout {
  button {
    width: 100%;
  }
}
