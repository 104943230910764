.page__404-container {
  text-align: center;
  margin-top: toRem(-36);

  @include breakpoint-desktop(medium) {
    margin-top: toRem(-80);
  }

  h1 {
    font-size: toRem(28);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-top: toRem(48);
    margin-bottom: 16px;

    @include breakpoint-desktop(medium) {
      font-size: toRem(32);
      line-height: toRem(38);
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  p {
    font-size: toRem(20);
    font-style: normal;
    font-weight: 400;
    color: var(--colorTextSecondary);
    line-height: toRem(28);
    margin: 0 0 toRem(40);
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto toRem(40);
  }

  > a.button,
  > button.button {
    width: 100%;
    margin-bottom: toRem(24);

    text-transform: none;
    &.secondary {
      text-transform: uppercase;
    }
  }
}
