@mixin border() {
  &::before {
    content: '';
    display: block;
    border-top: 1px solid var(--colorDividerBorder);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    @include breakpoint-desktop(min) {
      left: toRem(-60);
      right: toRem(-60);
    }
  }
}

footer {
  color: var(--colorTextSecondary);
  margin-top: auto;
  padding-top: 64px;
  padding-left: 20px;
  padding-right: 20px;

  a {
    text-decoration: none;
  }

  @include breakpoint-desktop(min) {
    padding-left: toRem(60);
    padding-right: toRem(60);
    display: flex;
    flex-direction: column;
  }

  .navigations {
    display: flex;
    flex-direction: column;

    @include breakpoint-desktop(min) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .external {
      display: flex;
      flex-direction: column;
      margin: 0 0 toRem(40);

      a {
        color: inherit;
        display: block;
        padding: 10px 0;

        &:hover {
          color: var(--colorBgWhite);
        }
      }

      @include breakpoint-desktop(min) {
        flex-direction: row;
        height: toRem(36);
        align-items: center;
        justify-content: flex-start;
        margin: 0;

        a {
          padding: 0;
        }

        li {
          display: flex;
          flex-direction: row;

          & + li {
            &::before {
              content: '•';
              display: block;
              width: toRem(40);
              text-align: center;
            }
          }
        }
      }
    }

    .socials {
      @include border;
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 24px;
      padding: 24px 0;
      justify-content: center;

      @include breakpoint-desktop(min) {
        &::before {
          display: none;
        }
      }

      @each $social in (facebook, instagram, x, youtube, twitch, tiktok, linkedin) {
        .#{$social} a {
          &::after {
            mask-image: url(/src/images/svg/socials/#{$social}.svg);
          }
        }
      }

      a {
        display: block;
        &::after {
          content: '';
          display: block;
          width: 24px;
          height: 24px;
          background: var(--colorTextSecondary);
          mask-position: center;
          mask-size: contain;
          transition: all 0.3s ease-out;
        }

        &:hover {
          &::after {
            background: var(--colorBgWhite);
          }
        }

        &:focus-visible {
          outline: 2px solid var(--colorOutline);
          outline-offset: 4px;
        }
      }
    }
  }

  .disclaimer {
    @include border;
    position: relative;
    font-size: 0.75rem;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    @include breakpoint-desktop(min) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .hint {
    display: none;

    &::before {
      content: '*';
      display: inline-block;
      color: var(--colorAccentLight);
    }
  }

  .extra {
    @include border;
    position: relative;

    padding: 24px 0;
    display: flex;

    align-items: center;
    justify-content: space-between;
    gap: 8px;

    > a {
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      color: var(--colorTextPrimary);
    }

    @include breakpoint-desktop(min) {
      display: none;
    }
  }
}

html {
  &[data-scope='authorize'],
  &[data-scope='register'],
  &[data-scope='password'] {
    .hint {
      display: block;
    }
  }
}
