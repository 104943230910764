:root {
  --colorAccent: #b41e1e;
  --colorAccentTransparent16: rgba(180, 30, 30, 0.16);
  --colorAccentLight: #f03232;
  --colorAccentDark: #6e0a0a;
  --colorAccentGold: #c8aa5a;
  --colorTextPrimary: #ffffff;
  --colorTextSecondary: #878787;
  --colorBgWhite: #ffffff;
  --colorBgPrimary: #0e0e0e;
  --colorBgSecondary: #1e1e1e;
  --colorBgTertiary: #292929;
  --colorBgQuarternary: #333333;
  --colorSpartaYellow: #faa519;
  --colorGreenPositive: #169c01;
  --colorDividerBorder: rgba(255, 255, 255, 0.12);
  --colorBgSemiTransparent: rgba(255, 255, 255, 0.04);
  --colorOutline: rgba(255, 255, 255, 0.32);

  --colorBgSemiTransparent4: rgba(255, 255, 255, 0.04);
  --colorBgSemiTransparent8: rgba(255, 255, 255, 0.08);
  --colorBgSemiTransparent12: rgba(255, 255, 255, 0.12);
  --colorBgSemiTransparent16: rgba(255, 255, 255, 0.16);
  --colorBgSemiTransparent32: rgba(255, 255, 255, 0.32);
}
