.password-recovery {
  font-size: toRem(20);
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: 150%;
  color: var(--colorTextSecondary);
  margin-bottom: toRem(32);

  strong {
    color: var(--colorTextPrimary);
  }
}

.form-password {
  display: flex;
  flex-direction: column;
  gap: 16px;

  button[type='submit'] {
    margin-top: toRem(32);
  }

  + h2 {
    margin-top: toRem(48);
    text-align: center;

    & + p {
      margin-top: toRem(16);
      text-align: center;
      line-height: 150%;

      a {
        text-transform: uppercase;
      }

      + p {
        text-align: center;
        margin-top: toRem(16);

        small {
          font-size: toRem(12);
          line-height: 171.429%;
          color: var(--colorTextSecondary);

          strong {
            font-weight: 500;
            color: var(--colorTextSecondary);
          }
        }
      }
    }
  }
}

.forgotten-password-wrapper {
  strong[data-email],
  .mailbox-hint {
    display: none;
  }

  &[data-alternate='true'] {
    .password-recovery {
      margin-bottom: toRem(48);
    }
    .form-group,
    h2 {
      display: none;

      + p {
        display: none;
      }
    }

    form {
      button {
        margin-top: 0;
      }
    }

    strong[data-email],
    .mailbox-hint {
      display: block;
    }
  }
}

.password-success-container {
  margin-top: toRem(64);
  text-align: center;

  > p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: relative;
    font-size: toRem(18);
    font-style: normal;
    font-weight: 400;
    line-height: toRem(28);

    &::before {
      content: '';
      left: 0;
      top: 2px;
      display: inline-block;
      width: toRem(24);
      height: toRem(24);
      background: transparent url(/src/images/svg/checked-contained.svg) no-repeat center;
    }
  }

  .button {
    margin-top: toRem(48);
    width: 100%;
  }
}
