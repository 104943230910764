.toggle {
  display: flex;
  flex-direction: row;
  gap: 16px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    left: -500vw;

    &:focus-visible {
      & + .view {
        outline: 2px solid var(--colorOutline);
        outline-offset: 4px;
      }
    }

    &:checked {
      & + .view {
        background: var(--colorAccentLight);

        &:before {
          transform: translate(27px, 3px);
        }
      }
    }

    &[required] {
      & ~ span:last-child {
        &::after {
          content: '*';
          color: var(--colorAccentLight);
          display: inline-block;
          transform: translateX(2px);
          font-size: 1.25rem;
          line-height: 0.5;
        }
      }
    }
  }

  .view {
    flex-basis: 48px;
    display: inline-block;
    height: 24px;
    border-radius: 24px;
    background: var(--colorDividerBorder);
    position: relative;
    transition: inherit;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(3px, 3px);
      width: 18px;
      height: 18px;
      border-radius: 20px;
      background: var(--colorBgWhite);
      transition: inherit;
    }

    & + span {
      flex: 1;
      font-size: toRem(14);
      line-height: 171.429%;
    }
  }
}
