@mixin breakpoint-desktop($dir: null) {
  @if $dir==min {
    @media screen and (min-width: 1261px) {
      @content;
    }
  } @else if $dir==medium {
    @media screen and (min-width: 1024px) {
      @content;
    }
  } @else if $dir==medium-max {
    @media screen and (max-width: 1024px) {
      @content;
    }
  } @else if $dir==xl {
    @media screen and (min-width: 1921px) {
      @content;
    }
  } @else {
    @media screen and (max-width: 1260px) {
      @content;
    }
  }
}

@mixin toRem($property, $value) {
  #{$property}: ($value / 16) + rem;
}

@function toRem($value) {
  $remValue: ($value / 16) + rem;
  @return $remValue;
}
