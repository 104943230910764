:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  -webkit-appearance: revert;
  appearance: revert;
}

:where(pre) {
  all: revert;
}

::placeholder {
  color: unset;
}

::marker {
  content: initial;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
}

:root {
  --colorAccent: #b41e1e;
  --colorAccentTransparent16: #b41e1e29;
  --colorAccentLight: #f03232;
  --colorAccentDark: #6e0a0a;
  --colorAccentGold: #c8aa5a;
  --colorTextPrimary: #fff;
  --colorTextSecondary: #878787;
  --colorBgWhite: #fff;
  --colorBgPrimary: #0e0e0e;
  --colorBgSecondary: #1e1e1e;
  --colorBgTertiary: #292929;
  --colorBgQuarternary: #333;
  --colorSpartaYellow: #faa519;
  --colorGreenPositive: #169c01;
  --colorDividerBorder: #ffffff1f;
  --colorBgSemiTransparent: #ffffff0a;
  --colorOutline: #ffffff52;
  --colorBgSemiTransparent4: #ffffff0a;
  --colorBgSemiTransparent8: #ffffff14;
  --colorBgSemiTransparent12: #ffffff1f;
  --colorBgSemiTransparent16: #ffffff29;
  --colorBgSemiTransparent32: #ffffff52;
}

@font-face {
  font-family: Zelezna;
  src: url("Zelezna-Regular.5e48f3db.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Zelezna;
  src: url("Zelezna-Semibold.5da87a83.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

h1 {
  text-transform: uppercase;
  text-align: center;
  margin-top: 16px;
  font-size: 1.125rem;
  line-height: 150%;
}

h1 + * {
  margin-top: 4rem;
}

h2 {
  line-height: 150%;
}

a {
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

.input {
  border-bottom: 1px solid var(--colorDividerBorder);
  height: 3.0625rem;
  display: block;
  position: relative;
}

.input ::placeholder {
  opacity: 0;
}

.input:has(input:focus) {
  border-bottom: 1px solid var(--colorBgWhite);
}

.input input {
  cursor: pointer;
  width: 100%;
  height: 1.1875rem;
  font-size: 1rem;
  line-height: 1rem;
  position: absolute;
  bottom: 8px;
}

.input input[icon-placement="right"] {
  padding-right: 24px;
}

.input input:not(:placeholder-shown) ~ [data-action="show-password"] {
  display: block;
}

:-webkit-any(.input input:focus, .input input:-webkit-autofill, .input input:-webkit-autofill, .input input:not(:placeholder-shown)) {
  cursor: unset;
}

:is(.input input:focus, .input input:autofill, .input input:autofill, .input input:not(:placeholder-shown)) {
  cursor: unset;
}

:-webkit-any(.input input:focus ~ .placeholder, .input input:-webkit-autofill ~ .placeholder, .input input:-webkit-autofill ~ .placeholder, .input input:not(:placeholder-shown) ~ .placeholder) {
  transform: translateY(0)scale(.75);
}

:is(.input input:focus ~ .placeholder, .input input:autofill ~ .placeholder, .input input:autofill ~ .placeholder, .input input:not(:placeholder-shown) ~ .placeholder) {
  transform: translateY(0)scale(.75);
}

.input input[required] ~ .placeholder:after {
  content: "*";
  color: var(--colorAccentLight);
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(calc(100% + 2px))translateY(-.2rem);
}

.input .placeholder {
  color: var(--colorTextSecondary);
  cursor: pointer;
  transform-origin: 0 0;
  font-size: 1rem;
  line-height: 1rem;
  transition: inherit;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(24px);
}

.input [data-action="show-password"] {
  -webkit-user-select: none;
  user-select: none;
  text-indent: -500vw;
  cursor: pointer;
  background: url("eye-open.aa7a1b38.svg") center / contain no-repeat;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  transition: inherit;
  display: none;
  position: absolute;
  bottom: 8px;
  right: 0;
}

.input [data-action="show-password"]:focus-visible {
  outline: 2px solid var(--colorOutline);
  outline-offset: 4px;
}

.input [data-action="show-password"][data-i18n="pages.common.hidePassword"] {
  background: url("eye-closed.b1ad374b.svg") no-repeat;
}

.select {
  border-bottom: 1px solid var(--colorDividerBorder);
  cursor: pointer;
  height: 3.0625rem;
  display: block;
  position: relative;
}

.select:after {
  content: "";
  z-index: -1;
  pointer-events: none;
  background: url("chevron-down.1c421f7d.svg") center / contain no-repeat;
  width: 20px;
  height: 20px;
  transition: inherit;
  display: block;
  position: absolute;
  bottom: 8px;
  right: 0;
}

.select:has(select:focus) {
  border-bottom: 1px solid var(--colorBgWhite);
}

.select select {
  z-index: 0;
  color: var(--colorTextPrimary);
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  padding-top: 24px;
  font-size: 1rem;
  line-height: 1rem;
  position: relative;
}

.select select:focus {
  cursor: unset;
}

.select select:not(:has(option[value=""]:checked)) {
  cursor: unset;
}

.select select:focus + .placeholder {
  transform: translateY(0)scale(.75);
}

.select select:not(:has(option[value=""]:checked)) + .placeholder {
  transform: translateY(0)scale(.75);
}

.select select[data-selected="true"] {
  cursor: unset;
}

.select select[data-selected="true"] + .placeholder {
  transform: translateY(0)scale(.75);
}

.select select[required] + .placeholder:after {
  content: "*";
  color: var(--colorAccentLight);
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(calc(100% + 2px))translateY(-.2rem);
}

.select .placeholder {
  z-index: -1;
  pointer-events: none;
  color: var(--colorTextSecondary);
  transform-origin: 0 0;
  font-size: 1rem;
  line-height: 1rem;
  transition: inherit;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(24px);
}

.select [data-action="show-password"] {
  text-indent: -500vw;
  cursor: pointer;
  background: url("eye-open.aa7a1b38.svg") center / contain no-repeat;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  transition: inherit;
  display: block;
  position: absolute;
  bottom: 8px;
  right: 0;
}

.select [data-action="show-password"]:focus-visible {
  outline: 2px solid var(--colorOutline);
  outline-offset: 4px;
}

select.language-select {
  text-transform: uppercase;
  color: var(--colorTextPrimary);
  -webkit-appearance: none;
  appearance: none;
  background-image: url("chevron-down.1c421f7d.svg");
  background-position: right 0 center;
  background-repeat: no-repeat;
  background-size: 24px;
  border: 1px solid #0000;
  border-radius: 0;
  flex: none;
  width: auto;
  padding: 3px 24px 3px 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

select.language-select:not(:disabled) {
  cursor: pointer;
}

html[data-os="windows"][user-agent*="Chrome"] select option, html[data-os="windows"][user-agent*="Chrome"] select optgroup, html[data-os="windows"][user-agent*="Chrome"] .select select option, html[data-os="windows"][user-agent*="Chrome"] .select select optgroup {
  color: #000;
}

.button {
  border: 1px solid var(--colorDividerBorder);
  cursor: pointer;
  background: var(--colorBgSemiTransparent);
  -webkit-backdrop-filter: blur(64px);
  backdrop-filter: blur(64px);
  border-radius: 3.125rem;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 3rem;
  padding: 0 2rem;
  font-weight: 500;
  text-decoration: none;
  transition: color .25s ease-in-out, background-color .25s ease-in-out, transform .15s cubic-bezier(.445, .05, .55, .95), box-shadow .2s ease-in-out, border-color .2s ease-in-out;
  display: inline-flex;
}

.button:focus-visible {
  outline: 2px solid var(--colorOutline);
  outline-offset: 4px;
}

.button:hover {
  background: #ffffff14;
  border: 1px solid #ffffff52;
}

.button.primary {
  background: var(--colorAccent);
  border-color: var(--colorAccent);
  text-transform: uppercase;
}

.button.primary:active {
  box-shadow: none;
  border-color: var(--colorAccentLight);
  transform: scale(.98);
}

.button.primary:hover {
  background: var(--colorAccentLight);
  border-color: var(--colorAccentLight);
}

.button.primary[data-loading="true"], .button.primary:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  background: var(--colorAccentTransparent16);
  color: var(--colorBgSemiTransparent32);
  border-color: #0000;
}

.button.tertiary {
  color: var(--colorTextPrimary);
  text-transform: uppercase;
  background: none;
  border: none;
  border-radius: 0;
  height: auto;
  min-height: auto;
  padding: 4px 0;
  text-decoration: underline;
}

.button[data-loading="true"] {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.button[data-loading="true"]:before {
  content: "";
  background: url("loading-indicator.a3c4d171.svg") center / contain no-repeat;
  width: 20px;
  height: 20px;
  animation: .6s linear infinite both rotate-center;
  display: inline-block;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.toggle {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  gap: 16px;
  display: flex;
}

.toggle input {
  position: absolute;
  left: -500vw;
}

.toggle input:focus-visible + .view {
  outline: 2px solid var(--colorOutline);
  outline-offset: 4px;
}

.toggle input:checked + .view {
  background: var(--colorAccentLight);
}

.toggle input:checked + .view:before {
  transform: translate(27px, 3px);
}

.toggle input[required] ~ span:last-child:after {
  content: "*";
  color: var(--colorAccentLight);
  font-size: 1.25rem;
  line-height: .5;
  display: inline-block;
  transform: translateX(2px);
}

.toggle .view {
  background: var(--colorDividerBorder);
  border-radius: 24px;
  flex-basis: 48px;
  height: 24px;
  transition: inherit;
  display: inline-block;
  position: relative;
}

.toggle .view:before {
  content: "";
  background: var(--colorBgWhite);
  border-radius: 20px;
  width: 18px;
  height: 18px;
  transition: inherit;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(3px, 3px);
}

.toggle .view + span {
  flex: 1;
  font-size: .875rem;
  line-height: 171.429%;
}

form {
  min-width: 100%;
}

form [data-step] {
  flex-direction: column;
  gap: 24px;
  height: 0;
  overflow: hidden;
}

form [data-step].active {
  height: auto;
  display: flex;
  overflow: visible;
}

form [data-step] :not(button) + button {
  margin-top: 24px;
}

.form-group[data-focused="false"][data-empty="false"] .blur-placeholder {
  opacity: 1;
}

.form-group[data-invalid="true"] .placeholder, .form-group[data-invalid="true"] .blur-placeholder {
  color: var(--colorAccentLight);
}

.form-group[data-invalid="true"] input, .form-group[data-invalid="true"] select, .form-group[data-invalid="true"] textarea {
  border-color: var(--colorError);
}

.form-group[data-invalid="true"] input[aria-invalid="true"] {
  background-image: url("alert-circle.7161aca0.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  padding-right: 24px;
}

.form-group[data-invalid="true"] input[aria-invalid="true"][icon-placement="right"] {
  padding-right: 44px;
}

.form-group[data-invalid="true"] input[aria-invalid="true"][icon-placement="right"]:not(:placeholder-shown) ~ [data-action="show-password"] {
  right: 24px;
}

.form-group[data-invalid="true"] .field-error {
  color: var(--colorAccentLight);
  text-align: left;
  margin-top: 8px;
  font-size: .75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%;
  display: block;
}

.form-group[data-invalid="true"] .field-error + .field-error {
  margin-top: 4px;
}

.blur-placeholder {
  opacity: 0;
  pointer-events: none;
  height: 1.1875rem;
  font-size: 1rem;
  line-height: 1rem;
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
}

.blur-placeholder[aria-hidden="true"] {
  background-color: #111;
}

.toast-region {
  flex-direction: column;
  gap: 8px;
  display: flex;
  position: fixed;
  bottom: 60px;
  left: 20px;
  right: 20px;
}

@media screen and (min-width: 1261px) {
  .toast-region {
    left: unset;
    right: 60px;
  }
}

.toast {
  border: 1px solid var(--colorBgSemiTransparent12);
  background: var(--colorBgSemiTransparent4);
  -webkit-backdrop-filter: blur(64px);
  backdrop-filter: blur(64px);
  color: var(--colorTextPrimary);
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 24px;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  animation: .44s cubic-bezier(.25, .46, .45, .94) both toast-flip-in;
  display: flex;
  box-shadow: 0 16px 40px #0e0e0e66;
}

.toast .close {
  box-shadow: none;
  text-indent: -9999px;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  background: url("x-01.2a77fa09.svg") center no-repeat;
  border: 0;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.toast .close:focus-visible {
  outline: 2px solid var(--colorOutline);
  outline-offset: 4px;
}

@keyframes toast-flip-in {
  0% {
    opacity: 0;
    transform: rotateX(80deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0);
  }
}

.form-register {
  margin-top: 4rem;
}

.form-register > div.active {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

.form-register .date-of-birth {
  flex-direction: row;
  gap: 2rem;
  display: flex;
}

.form-register .date-of-birth > * {
  flex: 1 1 0;
}

.form-register .date-of-birth select {
  text-transform: capitalize;
}

.form-register .date-of-birth + span[data-i18n="validations.required"], .form-register .date-of-birth + span[data-i18n="validations.required"] + span[data-i18n="validations.required"], .form-register .date-of-birth + span[data-i18n="validations.required"] + span[data-i18n="validations.required"] + span[data-i18n="validations.required"] {
  display: none;
}

.form-register .date-of-birth + span[data-i18n="validations.required"]:first-of-type {
  display: block;
}

.form-register .submit {
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
}

.form-register .submit button {
  margin-top: 1.5rem;
}

.form-register .alt-login .or {
  padding: 8px 0 2rem;
}

.form-register .phone-number-container {
  flex-direction: row;
  gap: 16px;
  display: flex;
}

.form-register .phone-number-container .form-group {
  flex: 1;
}

.form-register .phone-number-container .form-group.phone-prefix {
  flex: 0 0 5.5rem;
}

.select-year .select-year-button_info {
  box-shadow: none;
  color: #fff;
  background: var(--colorBgPrimary);
  z-index: 2;
  border: 0;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  bottom: 6px;
  right: 0;
}

.select-year .select-year-button_info:before {
  content: "";
  background: #fff;
  width: 20px;
  height: 20px;
  display: block;
  -webkit-mask-image: url("alert-circle.7161aca0.svg");
  mask-image: url("alert-circle.7161aca0.svg");
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.select-year .select-year-button_info span {
  border: 1px solid var(--colorBgSemiTransparent12);
  opacity: 0;
  background: var(--colorBgSecondary);
  pointer-events: none;
  z-index: 5;
  width: 360px;
  max-width: 90vw;
  padding: 8px 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  transition: opacity .2s ease-in-out;
  position: absolute;
  bottom: calc(100% + 12px);
  right: -10px;
}

.select-year .select-year-button_info span:after {
  content: "";
  border-style: solid;
  border-width: 8px 10px 0;
  border-color: var(--colorBgSecondary) transparent transparent transparent;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -8px;
  right: 10px;
  transform: rotate(0);
}

.select-year .select-year-button_info span:before {
  content: "";
  border: 11px solid #0000;
  border-top: 9px solid #ffffff52;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -9px;
  right: 9px;
  transform: rotate(0);
}

.select-year .select-year-button_info:hover span, .select-year .select-year-button_info:focus span {
  opacity: 1;
  pointer-events: all;
}

.select-year select[data-selected="true"] + span + .select-year-button_info {
  display: block;
}

.form-authorize {
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 4rem;
  display: flex;
}

.form-authorize h2 {
  text-align: center;
  margin-top: 1.5rem;
}

.form-authorize h2 + p {
  text-align: center;
  margin-top: -8px;
  line-height: 150%;
}

.form-authorize h2 + p a {
  text-transform: uppercase;
}

.form-authorize .forgotten-password {
  color: var(--colorTextSecondary);
  align-self: flex-end;
  margin-top: -1rem;
  font-size: .75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%;
  text-decoration: none;
}

.form-authorize .submit {
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
}

.form-authorize .submit button {
  margin-top: 1.5rem;
}

.or {
  text-align: center;
  text-transform: uppercase;
  padding: 2rem 0;
  line-height: 150%;
}

.alt-login {
  text-align: center;
}

.alt-login h2 {
  text-align: center;
  margin-top: 3rem;
}

.alt-login h2 + p {
  text-align: center;
  margin-top: 1rem;
  line-height: 150%;
}

.alt-login h2 + p a {
  text-transform: uppercase;
}

.alt-login a {
  gap: 3px;
  width: 100%;
  position: relative;
}

.alt-login ul {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.alt-login .facebook:before {
  content: "";
  background: var(--colorTextPrimary);
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 11px;
  left: 12px;
  -webkit-mask-image: url("facebook.9ec27ea4.svg");
  mask-image: url("facebook.9ec27ea4.svg");
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.alt-login .apple:before {
  content: "";
  background: var(--colorTextPrimary);
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 11px;
  left: 12px;
  -webkit-mask-image: url("apple.7b9a041c.svg");
  mask-image: url("apple.7b9a041c.svg");
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.alt-login .google:before {
  content: "";
  background: var(--colorTextPrimary);
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 11px;
  left: 12px;
  -webkit-mask-image: url("google.f942a080.svg");
  mask-image: url("google.f942a080.svg");
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.form-verify {
  text-align: center;
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.form-verify button[type="submit"] {
  margin-top: 2rem;
}

.form-verify p {
  color: var(--colorTextSecondary);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.form-verify p strong {
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.form-verify p small {
  font-size: .75rem;
  line-height: 171.429%;
}

.form-verify p small strong {
  color: var(--colorTextSecondary);
}

.form-verify-signout {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 3rem;
  display: flex;
}

.verified-success-container > p {
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
  display: flex;
  position: relative;
}

.verified-success-container > p:before {
  content: "";
  background: url("checked-contained.a1c99926.svg") center no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  top: 2px;
  left: 0;
}

.verified-success-container .button {
  width: 100%;
  margin-top: 3rem;
}

.form-consent {
  flex-direction: column;
  margin-top: 2rem;
  display: flex;
}

.form-consent ul {
  margin-top: 8px;
  padding-left: 2rem;
  list-style: disc;
}

.form-consent ul li {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.form-consent button[type="submit"] {
  margin: 2rem 0 1.5rem;
}

.form-consent button[type="submit"][data-loading="true"] + button {
  pointer-events: none;
  color: var(--colorBgSemiTransparent32);
  background: var(--colorBgSemiTransparent32);
  -webkit-backdrop-filter: blur(64px);
  backdrop-filter: blur(64px);
  border-color: #0000;
}

.form-consent p {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.form-consent p strong {
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.form-consent p small {
  color: var(--colorTextPrimary);
  margin-top: 1.5rem;
  font-size: 1rem;
  line-height: 171.429%;
  display: block;
}

.form-consent p small a {
  color: var(--colorTextPrimary);
  font-size: 1rem;
  line-height: 150%;
}

.consent-page-container > p {
  margin-top: 1.5rem;
  line-height: 1.5rem;
}

.form-consent-signout button {
  width: 100%;
}

.password-recovery {
  text-align: center;
  color: var(--colorTextSecondary);
  margin-bottom: 2rem;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.password-recovery strong {
  color: var(--colorTextPrimary);
}

.form-password {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.form-password button[type="submit"] {
  margin-top: 2rem;
}

.form-password + h2 {
  text-align: center;
  margin-top: 3rem;
}

.form-password + h2 + p {
  text-align: center;
  margin-top: 1rem;
  line-height: 150%;
}

.form-password + h2 + p a {
  text-transform: uppercase;
}

.form-password + h2 + p + p {
  text-align: center;
  margin-top: 1rem;
}

.form-password + h2 + p + p small {
  color: var(--colorTextSecondary);
  font-size: .75rem;
  line-height: 171.429%;
}

.form-password + h2 + p + p small strong {
  color: var(--colorTextSecondary);
  font-weight: 500;
}

.forgotten-password-wrapper strong[data-email], .forgotten-password-wrapper .mailbox-hint {
  display: none;
}

.forgotten-password-wrapper[data-alternate="true"] .password-recovery {
  margin-bottom: 3rem;
}

.forgotten-password-wrapper[data-alternate="true"] .form-group, .forgotten-password-wrapper[data-alternate="true"] h2, .forgotten-password-wrapper[data-alternate="true"] .form-group + p, .forgotten-password-wrapper[data-alternate="true"] h2 + p {
  display: none;
}

.forgotten-password-wrapper[data-alternate="true"] form button {
  margin-top: 0;
}

.forgotten-password-wrapper[data-alternate="true"] strong[data-email], .forgotten-password-wrapper[data-alternate="true"] .mailbox-hint {
  display: block;
}

.password-success-container {
  text-align: center;
  margin-top: 4rem;
}

.password-success-container > p {
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
  display: flex;
  position: relative;
}

.password-success-container > p:before {
  content: "";
  background: url("checked-contained.a1c99926.svg") center no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  top: 2px;
  left: 0;
}

.password-success-container .button {
  width: 100%;
  margin-top: 3rem;
}

.page__error-container .logo {
  margin-top: 7.25rem;
}

.page__error-container h1 {
  text-transform: uppercase;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.page__error-container p {
  color: var(--colorTextSecondary);
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;
}

.page__error-container p:not(:empty) {
  margin-bottom: 3rem;
}

.page__error-container .button {
  text-transform: none;
  width: 100%;
  display: flex;
}

.page__error-container .button.secondary {
  text-transform: uppercase;
}

.page__error-container .button:last-of-type {
  margin-top: 24px;
}

.page__404-container {
  text-align: center;
  margin-top: -2.25rem;
}

@media screen and (min-width: 1024px) {
  .page__404-container {
    margin-top: -5rem;
  }
}

.page__404-container h1 {
  text-transform: uppercase;
  margin-top: 3rem;
  margin-bottom: 16px;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (min-width: 1024px) {
  .page__404-container h1 {
    margin-left: -20px;
    margin-right: -20px;
    font-size: 2rem;
    line-height: 2.375rem;
  }
}

.page__404-container p {
  color: var(--colorTextSecondary);
  margin: 0 0 2.5rem;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
}

.page__404-container img {
  max-width: 100%;
  height: auto;
  margin: 0 auto 2.5rem;
  display: block;
}

.page__404-container > a.button, .page__404-container > button.button {
  text-transform: none;
  width: 100%;
  margin-bottom: 1.5rem;
}

.page__404-container > a.button.secondary, .page__404-container > button.button.secondary {
  text-transform: uppercase;
}

.page__sso_already_used-container .logo {
  margin-top: 7.25rem;
}

.page__sso_already_used-container h1 {
  text-transform: uppercase;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.page__sso_already_used-container > p {
  color: var(--colorTextSecondary);
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
}

.page__sso_already_used-container > p > span {
  padding: 0 10px;
  display: inline-block;
}

@media screen and (min-width: 1024px) {
  .page__sso_already_used-container > p {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
}

.page__sso_already_used-container > p strong {
  color: var(--colorTextPrimary);
  font-weight: 500;
}

.page__sso_already_used-container > p small {
  font-size: 14px;
  line-height: 24px;
}

.page__sso_already_used-container .button {
  text-transform: uppercase;
  width: 100%;
  margin: 3rem 0 1rem;
  display: flex;
}

footer {
  color: var(--colorTextSecondary);
  margin-top: auto;
  padding-top: 64px;
  padding-left: 20px;
  padding-right: 20px;
}

footer a {
  text-decoration: none;
}

@media screen and (min-width: 1261px) {
  footer {
    flex-direction: column;
    padding-left: 3.75rem;
    padding-right: 3.75rem;
    display: flex;
  }
}

footer .navigations {
  flex-direction: column;
  display: flex;
}

@media screen and (min-width: 1261px) {
  footer .navigations {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

footer .navigations .external {
  flex-direction: column;
  margin: 0 0 2.5rem;
  display: flex;
}

footer .navigations .external a {
  color: inherit;
  padding: 10px 0;
  display: block;
}

footer .navigations .external a:hover {
  color: var(--colorBgWhite);
}

@media screen and (min-width: 1261px) {
  footer .navigations .external {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 2.25rem;
    margin: 0;
  }

  footer .navigations .external a {
    padding: 0;
  }

  footer .navigations .external li {
    flex-direction: row;
    display: flex;
  }

  footer .navigations .external li + li:before {
    content: "•";
    text-align: center;
    width: 2.5rem;
    display: block;
  }
}

footer .navigations .socials {
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  padding: 24px 0;
  display: flex;
  position: relative;
}

footer .navigations .socials:before {
  content: "";
  border-top: 1px solid var(--colorDividerBorder);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media screen and (min-width: 1261px) {
  footer .navigations .socials:before {
    display: none;
    left: -3.75rem;
    right: -3.75rem;
  }
}

footer .navigations .socials .facebook a:after {
  -webkit-mask-image: url("facebook.9ec27ea4.svg");
  mask-image: url("facebook.9ec27ea4.svg");
}

footer .navigations .socials .instagram a:after {
  -webkit-mask-image: url("instagram.e3b2e5b2.svg");
  mask-image: url("instagram.e3b2e5b2.svg");
}

footer .navigations .socials .x a:after {
  -webkit-mask-image: url("x.955ebfad.svg");
  mask-image: url("x.955ebfad.svg");
}

footer .navigations .socials .youtube a:after {
  -webkit-mask-image: url("youtube.8ddca57b.svg");
  mask-image: url("youtube.8ddca57b.svg");
}

footer .navigations .socials .twitch a:after {
  -webkit-mask-image: url("twitch.d6b1b8ce.svg");
  mask-image: url("twitch.d6b1b8ce.svg");
}

footer .navigations .socials .tiktok a:after {
  -webkit-mask-image: url("tiktok.92f1d0c6.svg");
  mask-image: url("tiktok.92f1d0c6.svg");
}

footer .navigations .socials .linkedin a:after {
  -webkit-mask-image: url("linkedin.3b8e3c2b.svg");
  mask-image: url("linkedin.3b8e3c2b.svg");
}

footer .navigations .socials a {
  display: block;
}

footer .navigations .socials a:after {
  content: "";
  background: var(--colorTextSecondary);
  width: 24px;
  height: 24px;
  transition: all .3s ease-out;
  display: block;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
}

footer .navigations .socials a:hover:after {
  background: var(--colorBgWhite);
}

footer .navigations .socials a:focus-visible {
  outline: 2px solid var(--colorOutline);
  outline-offset: 4px;
}

footer .disclaimer {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 24px 0;
  font-size: .75rem;
  display: flex;
  position: relative;
}

footer .disclaimer:before {
  content: "";
  border-top: 1px solid var(--colorDividerBorder);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media screen and (min-width: 1261px) {
  footer .disclaimer:before {
    left: -3.75rem;
    right: -3.75rem;
  }

  footer .disclaimer {
    flex-direction: row;
    justify-content: space-between;
  }
}

footer .hint {
  display: none;
}

footer .hint:before {
  content: "*";
  color: var(--colorAccentLight);
  display: inline-block;
}

footer .extra {
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 24px 0;
  display: flex;
  position: relative;
}

footer .extra:before {
  content: "";
  border-top: 1px solid var(--colorDividerBorder);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media screen and (min-width: 1261px) {
  footer .extra:before {
    left: -3.75rem;
    right: -3.75rem;
  }
}

footer .extra > a {
  text-transform: uppercase;
  color: var(--colorTextPrimary);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
}

@media screen and (min-width: 1261px) {
  footer .extra {
    display: none;
  }
}

html[data-scope="authorize"] .hint, html[data-scope="register"] .hint, html[data-scope="password"] .hint {
  display: block;
}

:root {
  color-scheme: dark;
}

html {
  min-height: 100%;
  font-size: 15px;
}

@media screen and (min-width: 1921px) {
  html {
    font-size: 16px;
  }
}

body {
  color: var(--colorTextPrimary);
  background: linear-gradient(127deg, #ffffff0f 0%, #fff0 59.28%);
  background-color: var(--colorBgPrimary);
  background-repeat: repeat-x;
  flex-direction: column;
  min-height: 100vh;
  margin: auto;
  font-family: Zelezna, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  display: flex;
}

header {
  visibility: hidden;
  margin-bottom: 2.25rem;
}

@media screen and (min-width: 1261px) {
  header {
    visibility: visible;
    margin-bottom: 5rem;
  }
}

header nav {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  height: 4.5rem;
  padding: 0 16px;
  display: flex;
}

@media screen and (min-width: 1261px) {
  header nav {
    height: 5rem;
    padding: 0 3.75rem;
  }
}

header nav .nav__previous {
  align-items: center;
  gap: 16px;
  margin-right: auto;
  display: inline-flex;
  position: relative;
}

header nav .nav__previous:before {
  content: "";
  background: url("arrow-left.0a4f2f55.svg") center no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
}

header nav > a {
  text-transform: uppercase;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
}

header nav > span[role="separator"] {
  background: url("divider.a2e2ca21.svg") center no-repeat;
  flex: none;
  width: 8px;
  height: 24px;
  display: inline-block;
}

main {
  width: 100%;
  max-width: 388px;
  margin: 0 auto;
}

@media screen and (max-width: 1260px) {
  main {
    padding: 0 20px;
  }
}

main .logo {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  display: flex;
}

@media screen and (max-width: 1260px) {
  html[data-scope="register"] header {
    visibility: visible;
    margin-bottom: 40px;
  }

  html[data-scope="register"] header nav > :not(.nav__previous) {
    visibility: hidden;
    display: none;
  }
}

a, button, .input, select.language-select, .select, .toggle {
  transition: all .3s ease-out;
}

a:focus-visible, select.language-select:focus-visible {
  outline: 2px solid var(--colorOutline);
  outline-offset: 4px;
}

input[data-autocompleted] {
  background-color: #0000 !important;
}

::-ms-reveal {
  display: none;
}

.user-card {
  background: var(--colorBgTertiary, #292929);
  -webkit-backdrop-filter: blur(64px);
  backdrop-filter: blur(64px);
  word-break: break-all;
  border-radius: 88px;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
  padding: 16px;
  display: flex;
}

.user-card > picture {
  border-radius: 100%;
  flex: none;
  width: 4rem;
  height: 4rem;
  position: relative;
  overflow: hidden;
}

.user-card > picture img {
  color: #0000;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.user-card strong {
  text-transform: uppercase;
  font-size: 1rem;
}

.user-card span {
  color: var(--colorTextSecondary);
  margin-top: 4px;
  font-size: .75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 128.571%;
  display: block;
}

@media screen and (min-width: 1261px) {
  .user-card {
    gap: 20px;
    padding: 20px;
  }

  .user-card strong {
    font-size: 1.25rem;
  }

  .user-card span {
    font-size: .875rem;
  }
}

::-webkit-contacts-auto-fill-button {
  color: #fff;
  background-color: #fff;
}

::-webkit-credentials-auto-fill-button {
  color: #fff;
  background-color: #fff;
}

:-webkit-any(input:-webkit-autofill, input[data-com-onepassword-filled]) {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff !important;
}

:is(input:autofill, input[data-com-onepassword-filled]) {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff !important;
}

/*# sourceMappingURL=verified.074aee25.css.map */
