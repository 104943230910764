.page__sso_already_used-container {
  .logo {
    margin-top: toRem(116);
  }

  h1 {
    font-size: toRem(18);
    font-style: normal;
    font-weight: 400;
    line-height: toRem(24); /* 133.333% */
    text-transform: uppercase;
  }

  > p {
    color: var(--colorTextSecondary);
    text-align: center;
    font-style: normal;
    font-weight: 400;

    > span {
      display: inline-block;
      padding: 0 10px;
    }

    font-size: toRem(18);
    line-height: toRem(28); /* 150% */

    @include breakpoint-desktop(medium) {
      font-size: toRem(20);
      line-height: toRem(30); /* 150% */
    }

    strong {
      font-weight: 500;
      color: var(--colorTextPrimary);
    }

    small {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .button {
    display: flex;
    width: 100%;
    text-transform: uppercase;
    margin: toRem(48) 0 toRem(16);
  }
}
