.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: toRem(48);
  padding: 0 toRem(32);
  border-radius: toRem(50);
  border: 1px solid var(--colorDividerBorder);
  cursor: pointer;
  background: var(--colorBgSemiTransparent);
  backdrop-filter: blur(64px);
  text-decoration: none;
  font-weight: 500;

  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    transform 0.15s cubic-bezier(0.445, 0.05, 0.55, 0.95), box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;

  &:focus-visible {
    outline: 2px solid var(--colorOutline);
    outline-offset: 4px;
  }

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.32);
    background: rgba(255, 255, 255, 0.08);
  }

  &.primary {
    background: var(--colorAccent);
    border-color: var(--colorAccent);
    text-transform: uppercase;

    &:active {
      box-shadow: none;
      border-color: var(--colorAccentLight);
      transform: scale(0.98);
    }

    &:hover {
      background: var(--colorAccentLight);
      border-color: var(--colorAccentLight);
    }

    &[data-loading='true'],
    &:disabled {
      pointer-events: none;
      user-select: none;
      background: var(--colorAccentTransparent16);
      border-color: transparent;
      color: var(--colorBgSemiTransparent32);
    }
  }

  &.tertiary {
    padding: 4px 0;
    background: transparent;
    text-decoration: underline;
    color: var(--colorTextPrimary);
    border: none;
    border-radius: 0;
    text-transform: uppercase;
    min-height: auto;
    height: auto;
  }

  &[data-loading='true'] {
    pointer-events: none;
    user-select: none;

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background: transparent url(/src/images/svg/loading-indicator.svg) no-repeat center;
      background-size: contain;
      animation: rotate-center 0.6s linear infinite both;
    }
  }
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
