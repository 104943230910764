.input {
  height: toRem(49);
  display: block;
  position: relative;
  border-bottom: 1px solid var(--colorDividerBorder);

  ::placeholder {
    opacity: 0;
  }

  &:has(input:focus) {
    border-bottom: 1px solid var(--colorBgWhite);
  }

  input {
    width: 100%;
    font-size: toRem(16);
    line-height: toRem(16);
    height: toRem(19);
    position: absolute;
    bottom: 8px;
    cursor: pointer;

    &[icon-placement='right'] {
      padding-right: 24px;
    }

    &:not(:placeholder-shown) {
      & ~ [data-action='show-password'] {
        display: block;
      }
    }

    &:focus,
    &:autofill,
    &:-webkit-autofill,
    &:not(:placeholder-shown) {
      cursor: unset;

      ~ .placeholder {
        transform: translateY(0) scale(0.75);
      }
    }

    &[required] {
      ~ .placeholder {
        &::after {
          content: '*';
          color: var(--colorAccentLight);
          display: inline-block;
          position: absolute;
          right: 0;
          top: 0;
          transform: translateX(calc(100% + 2px)) translateY(-0.2rem);
        }
      }
    }
  }

  .placeholder {
    font-size: toRem(16);
    line-height: toRem(16);
    left: 0;
    top: 0;
    transform: translateY(24px);
    color: var(--colorTextSecondary);
    transition: inherit;
    position: absolute;
    cursor: pointer;
    transform-origin: top left;
  }

  [data-action='show-password'] {
    position: absolute;
    bottom: 8px;
    right: 0;
    display: none;
    user-select: none;
    width: 20px;
    height: 20px;
    background: url(/src/images/svg/eye-open.svg) no-repeat;
    background-position: center;
    background-size: contain;
    text-indent: -500vw;
    border-radius: 4px;
    cursor: pointer;
    transition: inherit;

    &:focus-visible {
      outline: 2px solid var(--colorOutline);
      outline-offset: 4px;
    }

    &[data-i18n='pages.common.hidePassword'] {
      background: url(/src/images/svg/eye-closed.svg) no-repeat;
    }
  }
}
