.select {
  height: toRem(49);
  display: block;
  position: relative;
  border-bottom: 1px solid var(--colorDividerBorder);
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    pointer-events: none;
    right: 0;
    bottom: 8px;
    width: 20px;
    height: 20px;
    background: url(/src/images/svg/chevron-down.svg) no-repeat;
    background-position: center;
    background-size: contain;
    transition: inherit;
  }

  &:has(select:focus) {
    border-bottom: 1px solid var(--colorBgWhite);
  }

  select {
    width: 100%;
    height: 100%;
    font-size: toRem(16);
    line-height: toRem(16);
    position: relative;
    z-index: 0;

    padding-top: 24px;

    color: var(--colorTextPrimary);
    appearance: none;

    &:focus,
    &:not(:has(option[value='']:checked)) {
      cursor: unset;

      & + .placeholder {
        transform: translateY(0) scale(0.75);
      }
    }
    &[data-selected='true'] {
      cursor: unset;

      & + .placeholder {
        transform: translateY(0) scale(0.75);
      }
    }

    &[required] {
      & + .placeholder {
        &::after {
          content: '*';
          color: var(--colorAccentLight);
          display: inline-block;
          position: absolute;
          right: 0;
          top: 0;
          transform: translateX(calc(100% + 2px)) translateY(-0.2rem);
        }
      }
    }
  }

  .placeholder {
    z-index: -1;
    pointer-events: none;
    font-size: toRem(16);
    line-height: toRem(16);
    left: 0;
    top: 0;
    transform: translateY(24px);
    color: var(--colorTextSecondary);
    transition: inherit;
    position: absolute;
    transform-origin: top left;
  }

  [data-action='show-password'] {
    position: absolute;
    bottom: 8px;
    right: 0;
    display: block;
    width: 20px;
    height: 20px;
    background: url(/src/images/svg/eye-open.svg) no-repeat;
    background-position: center;
    background-size: contain;
    text-indent: -500vw;
    border-radius: 4px;
    cursor: pointer;
    transition: inherit;

    &:focus-visible {
      outline: 2px solid var(--colorOutline);
      outline-offset: 4px;
    }
  }
}

select.language-select {
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  flex: 0 0 auto;
  width: auto;
  padding: 3px 24px 3px 0;
  line-height: 1.2;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: var(--colorTextPrimary);

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-image: url(/src/images/svg/chevron-down.svg);
  background-repeat: no-repeat;
  background-position: right 0 center;
  background-size: 24px;
  border: 1px solid transparent;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:not(:disabled) {
    cursor: pointer;
  }
}

html {
  &[data-os='windows'] {
    &[user-agent*='Chrome'] {
      select,
      .select select {
        option,
        optgroup {
          color: #000;
        }
      }
    }
  }
}
