.form-register {
  margin-top: toRem(64);

  > div {
    &.active {
      display: flex;
      flex-direction: column;
      gap: toRem(24);
    }
  }

  .date-of-birth {
    display: flex;
    flex-direction: row;
    gap: toRem(32);

    & > * {
      flex: 1 1 0px;
    }

    select {
      text-transform: capitalize;
    }

    + span[data-i18n='validations.required'] {
      display: none;

      + span[data-i18n='validations.required'] {
        display: none;

        + span[data-i18n='validations.required'] {
          display: none;
        }
      }

      &:first-of-type {
        display: block;
      }
    }
  }

  .submit {
    display: flex;
    flex-direction: column;
    gap: toRem(24);
    margin-top: toRem(24);

    button {
      margin-top: toRem(24);
    }
  }

  .alt-login {
    .or {
      padding: 8px 0 toRem(32);
    }
  }

  .phone-number-container {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .form-group {
      flex: 1 1;

      &.phone-prefix {
        flex: 0 0 toRem(88);
      }
    }
  }
}

.select-year {
  .select-year-button_info {
    display: none;
    position: absolute;
    right: 0px;
    bottom: 6px;
    border: 0;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    box-shadow: none;
    color: #fff;
    background: var(--colorBgPrimary);
    z-index: 2;

    &::before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      mask-image: url(/src/images/svg/alert-circle.svg);
      mask-size: contain;
      mask-repeat: no-repeat;
      background: #fff;
    }

    span {
      position: absolute;
      bottom: calc(100% + 12px);
      border: 1px solid var(--colorBgSemiTransparent12);
      width: 360px;
      max-width: 90vw;
      padding: 8px 12px;
      opacity: 0;
      right: -10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      background: var(--colorBgSecondary);
      pointer-events: none;
      z-index: 5;
      transition: opacity 0.2s ease-in-out;

      &::after {
        content: '';
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 8px 10px 0 10px;
        border-color: var(--colorBgSecondary) transparent transparent transparent;
        transform: rotate(0deg);
        position: absolute;
        bottom: -8px;
        right: 10px;
      }
      &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 9px 11px 0 11px;
        border-color: rgba(255, 255, 255, 0.32) transparent transparent transparent;
        transform: rotate(0deg);
        position: absolute;
        bottom: -9px;
        right: 9px;
      }
    }

    &:hover,
    &:focus {
      span {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  select[data-selected='true'] {
    + span {
      + .select-year-button_info {
        display: block;
      }
    }
  }
}
