.form-authorize {
  margin-top: toRem(64);
  display: flex;
  flex-direction: column;
  gap: toRem(24);

  h2 {
    margin-top: toRem(24);
    text-align: center;

    & + p {
      margin-top: -8px;
      text-align: center;
      line-height: 150%;

      a {
        text-transform: uppercase;
      }
    }
  }

  .forgotten-password {
    align-self: flex-end;
    font-size: toRem(12);
    font-style: normal;
    font-weight: 400;
    line-height: 133.333%;
    color: var(--colorTextSecondary);
    text-decoration: none;
    margin-top: toRem(-16);
  }

  .submit {
    display: flex;
    flex-direction: column;
    gap: toRem(24);
    margin-top: toRem(24);

    button {
      margin-top: toRem(24);
    }
  }
}

.or {
  line-height: 150%;
  padding: toRem(32) 0;
  text-align: center;
  text-transform: uppercase;
}

.alt-login {
  text-align: center;

  h2 {
    margin-top: toRem(48);
    text-align: center;

    & + p {
      margin-top: toRem(16);
      text-align: center;
      line-height: 150%;

      a {
        text-transform: uppercase;
      }
    }
  }

  a {
    width: 100%;
    position: relative;
    gap: 3px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  @each $social in (facebook, apple, google) {
    .#{$social}::before {
      content: '';
      display: block;
      height: 24px;
      width: 24px;
      mask-image: url(/src/images/svg/socials/#{$social}.svg);
      mask-size: contain;
      mask-repeat: no-repeat;
      background: var(--colorTextPrimary);
      position: absolute;
      left: 12px;
      top: 11px;
    }
  }
}
