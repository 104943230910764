@font-face {
  font-family: 'Zelezna';
  src: url('/src/fonts/zelezna/Zelezna-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Zelezna';
  src: url('/src/fonts/zelezna/Zelezna-Semibold.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
