.toast-region {
  position: fixed;
  bottom: 60px;
  left: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include breakpoint-desktop(min) {
    left: unset;
    right: 60px;
  }
}

.toast {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border: 1px solid var(--colorBgSemiTransparent12);
  background: var(--colorBgSemiTransparent4);
  box-shadow: 0px 16px 40px 0px rgba(14, 14, 14, 0.4);
  backdrop-filter: blur(64px);
  padding: 24px;

  color: var(--colorTextPrimary);

  font-size: toRem(14);
  font-style: normal;
  font-weight: 400;
  line-height: toRem(24);

  animation: toast-flip-in 0.44s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  .close {
    border: 0;
    box-shadow: none;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    text-indent: -9999px;
    overflow: hidden;
    backdrop-filter: none;
    background: transparent url(/src/images/svg/x-01.svg) no-repeat center;

    &:focus-visible {
      outline: 2px solid var(--colorOutline);
      outline-offset: 4px;
    }
  }
}

@keyframes toast-flip-in {
  0% {
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}
