.page__error-container {
  .logo {
    margin-top: toRem(116);
  }

  h1 {
    font-size: toRem(18);
    font-style: normal;
    font-weight: 400;
    line-height: toRem(24); /* 133.333% */
    text-transform: uppercase;
  }

  p {
    color: var(--colorTextSecondary);
    text-align: center;
    font-size: toRem(20);
    font-style: normal;
    font-weight: 400;
    line-height: toRem(30); /* 150% */

    &:not(:empty) {
      margin-bottom: toRem(48);
    }
  }

  .button {
    display: flex;
    width: 100%;
    text-transform: none;

    &.secondary {
      text-transform: uppercase;
    }
    &:last-of-type {
      margin-top: 24px;
    }

    &:last-of-type {
      margin-top: 24px;
    }
  }
}
