.form-verify {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;

  button[type='submit'] {
    margin-top: toRem(32);
  }

  p {
    font-size: toRem(20);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--colorTextSecondary);

    strong {
      font-weight: 500;
      color: var(--colorTextPrimary);
    }

    small {
      font-size: toRem(12);
      line-height: 171.429%;

      strong {
        color: var(--colorTextSecondary);
      }
    }
  }
}

.form-verify-signout {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: toRem(48);
}

.verified-success-container {
  > p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: relative;
    font-size: toRem(18);
    font-style: normal;
    font-weight: 400;
    line-height: toRem(28);

    &::before {
      content: '';
      left: 0;
      top: 2px;
      display: inline-block;
      width: toRem(24);
      height: toRem(24);
      background: transparent url(/src/images/svg/checked-contained.svg) no-repeat center;
    }
  }

  .button {
    margin-top: toRem(48);
    width: 100%;
  }
}
