form {
  min-width: 100%;

  [data-step] {
    flex-direction: column;
    gap: 24px;
    height: 0;
    overflow: hidden;

    &.active {
      height: auto;
      overflow: visible;
      display: flex;
    }

    *:not(button) + button {
      margin-top: 24px;
    }
  }
}

.form-group {
  &[data-focused='false'] {
    &[data-empty='false'] {
      .blur-placeholder {
        opacity: 1;
      }
    }
  }

  &[data-invalid='true'] {
    .placeholder {
      color: var(--colorAccentLight);
    }

    .blur-placeholder {
      color: var(--colorAccentLight);
    }

    input,
    select,
    textarea {
      border-color: var(--colorError);
    }

    input {
      &[aria-invalid='true'] {
        background-image: url(/src/images/svg/alert-circle.svg);
        background-repeat: no-repeat;
        background-position: right center;

        padding-right: 24px;

        &[icon-placement='right'] {
          padding-right: 44px;

          &:not(:placeholder-shown) ~ [data-action='show-password'] {
            right: 24px;
          }
        }
      }
    }

    .field-error {
      display: block;
      margin-top: 8px;
      font-size: toRem(12);
      font-style: normal;
      font-weight: 400;
      line-height: 133.333%;
      color: var(--colorAccentLight);
      text-align: left;

      + .field-error {
        margin-top: 4px;
      }
    }
  }
}

.blur-placeholder {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;

  font-size: toRem(16);
  line-height: toRem(16);
  height: toRem(19);

  &[aria-hidden='true'] {
    background-color: #111111;
  }
}
